import React from "react";
import { motion } from "framer-motion";
import SVGMicrosoft from "../../../assets/SVG/SVGMicrosoft";
import SVGStation from "../../../assets/SVG/SVGStation";
import SVGNvidia from "../../../assets/SVG/SVGNvidia";

const OurPartner = () => {
  // Animation variants for the logos
  const logoVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
    hover: { scale: 1.1, boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" }
  };

  // Divider line animation variants
  const lineVariants = {
    hidden: { width: "0%" },
    visible: { width: "100%", transition: { duration: 1, ease: "easeInOut" } }
  };

  return (
    <section className="w-full mx-auto my-12">
      {/* Top animated gradient line */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={lineVariants}
        className="my-4 w-full mx-auto h-[1px] bg-gradient-to-r from-slate-800 via-slate-100 to-slate-800"
      />

      <div className="p-10 flex flex-col justify-center items-center">
        <motion.h1
          className="landing-page-title-2 text-center-custom text-4xl text-slate-300 font-bold max-sm:text-2xl"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          Our Partners
        </motion.h1>

        <div className="w-full overflow-hidden flex justify-center items-center space-x-8 my-8 mx-auto">
          <motion.span
            className="w-[250px] h-[60px] text-slate-200 flex justify-center items-center"
            variants={logoVariants}
            initial="hidden"
            whileInView="visible"
            whileHover="hover"
          >
            <SVGMicrosoft />
          </motion.span>
          <motion.span
            className="w-[250px] h-[60px] text-slate-200 flex justify-center items-center"
            variants={logoVariants}
            initial="hidden"
            whileInView="visible"
            whileHover="hover"
          >
            <SVGStation />
          </motion.span>
          <motion.span
            className="w-[250px] h-[60px] text-slate-200 flex justify-center items-center"
            variants={logoVariants}
            initial="hidden"
            whileInView="visible"
            whileHover="hover"
          >
            <SVGNvidia />
          </motion.span>
        </div>
      </div>

      {/* Bottom animated gradient line */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={lineVariants}
        className="my-4 w-full mx-auto h-[1px] bg-gradient-to-r from-slate-800 via-slate-100 to-slate-800"
      />
    </section>
  );
};

export default OurPartner;

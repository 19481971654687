import { useEffect, useRef, useState } from "react";
import "../../index.css";
import HeaderComponent from "./Components/HeaderComponent";
import BuildAgent from "./Components/BuildAgent";
import SuperHero from "./Components/SuperHero";
import HomeTemplates from "./Components/HomeTemplates";
import ConversationFlow from "./Components/ConversationFlow";
import OurPartner from "./Components/OurPartner";
import BuddyVideo from "./Components/BuddyVideo";
import HappyClients from "./Components/HappyClients/HappyClients";
import HomePrice from "./Components/HomePrice";
import DashboardDemo from "./Components/DashboardDemo";


// GNtZzNEZ05WNIX6OFYuXbAvMRVO5Kc3u
const Home = () => {

  return (
  
      <div
        className="max-w-[1340px] h-auto  mx-auto flex flex-col max-sm:p-[10px]">

        {/* <iframe src="https://giphy.com/embed/45fvNo8yvdKXpybkcr" width="480" height="259" style={{}} frameBorder="0" class="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/embed/45fvNo8yvdKXpybkcr">via GIPHY</a></p> */}
        {/* <iframe src="https://giphy.com/embed/1M4RG1BsJYwSY" width="480" height="259" style={{}} frameBorder="0" class="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/1M4RG1BsJYwSY">via GIPHY</a></p> */}

        <HeaderComponent />
        <DashboardDemo />
        <BuildAgent />
        <div class="my-20 w-[80%] mx-auto h-[1px] bg-gradient-to-r from-slate-800 via-slate-100 to-slate-800"></div>
        <BuddyVideo />
        <div class="my-24 w-[80%] mx-auto h-[1px] bg-gradient-to-r from-slate-800 via-slate-100 to-slate-800"></div>
        <SuperHero />
        <div class="my-24 w-[80%] mx-auto h-[1px] bg-gradient-to-r from-slate-800 via-slate-100 to-slate-800"></div>
        <HomeTemplates />
        <ConversationFlow />
        <OurPartner />
        <HappyClients />
        <HomePrice />
      </div>
   
  );
};

export default Home;

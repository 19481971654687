import React, { useState } from "react";
import { FiMail, FiPhone, FiMapPin } from "react-icons/fi";
import { motion } from "framer-motion";

const ContactUs = () => {
  const [formData, setFormData] = useState({ name: "", email: "", message: "" });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setTimeout(() => {
      alert("Message sent successfully!");
      setFormData({ name: "", email: "", message: "" });
      setIsSubmitting(false);
    }, 2000);
  };

  return (
    <motion.div
      className="max-w-[1340px] mx-auto !pt-12 py-16 px-6 lg:px-8 flex flex-col items-center"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeOut" }}
    >
      <div className="w-full max-w-[1000px] mx-auto">
        {/* Header Section */}
        <h1 className="text-[26px] font-bold text-center mb-4 sm:text-[36px] bg-gradient-to-r from-[#E1E1E1] to-[#929292] bg-clip-text text-transparent">
          Get in Touch
        </h1>
        <p className="text-center text-[#b1b1b1] dark:text-[#d1d1d1] text-[16px] mb-12">
          We’re here to help! Reach out for support, partnership opportunities, or inquiries.
        </p>

        <div className="flex flex-col lg:flex-row gap-10 lg:gap-8">
          {/* Contact Info Section */}
          <div className="flex-1 p-8 rounded-xl shadow-md bg-[#1e1e2a] bg-opacity-60 backdrop-blur-lg backdrop-saturate-150">
            <h2 className="text-[20px] font-semibold text-white mb-6">Contact Information</h2>
            <div className="space-y-6">
              <div className="flex items-center gap-4 p-4 rounded-lg bg-[#2b2b40] bg-opacity-50 backdrop-blur-sm">
                <FiMail className="text-indigo-400 text-xl" />
                <div>
                  <h4 className="text-white font-medium">Email Us</h4>
                  <p className="text-sm text-[#cfcfcf]">support@faang.com</p>
                </div>
              </div>
              <div className="flex items-center gap-4 p-4 rounded-lg bg-[#2b2b40] bg-opacity-50 backdrop-blur-sm">
                <FiPhone className="text-indigo-400 text-xl" />
                <div>
                  <h4 className="text-white font-medium">Call Us</h4>
                  <p className="text-sm text-[#cfcfcf]">+1 (123) 456-7890</p>
                </div>
              </div>
              <div className="flex items-center gap-4 p-4 rounded-lg bg-[#2b2b40] bg-opacity-50 backdrop-blur-sm">
                <FiMapPin className="text-indigo-400 text-xl" />
                <div>
                  <h4 className="text-white font-medium">Visit Us</h4>
                  <p className="text-sm text-[#cfcfcf]">123 FAANG St., Silicon Valley, CA</p>
                </div>
              </div>
            </div>
          </div>

          {/* Contact Form Section */}
          <div className="flex-1 p-8 bg-[#1e1e2a] bg-opacity-60 backdrop-blur-lg backdrop-saturate-150 rounded-xl shadow-md">
            <h2 className="text-[20px] font-semibold text-white mb-6">Send Us a Message</h2>
            <form onSubmit={handleSubmit} className="flex flex-col gap-6">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                placeholder="Your Name"
                className="p-4 rounded-lg bg-[#2b2b40] bg-opacity-50 text-white placeholder-[#a1a1a1] focus:outline-none focus:ring-2 focus:ring-indigo-400"
              />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder="Your Email"
                className="p-4 rounded-lg bg-[#2b2b40] bg-opacity-50 text-white placeholder-[#a1a1a1] focus:outline-none focus:ring-2 focus:ring-indigo-400"
              />
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                rows="4"
                placeholder="Your Message"
                className="p-4 rounded-lg bg-[#2b2b40] bg-opacity-50 text-white placeholder-[#a1a1a1] focus:outline-none focus:ring-2 focus:ring-indigo-400"
              ></textarea>
              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full py-3 bg-indigo-500 text-white font-semibold rounded-full shadow-md hover:bg-indigo-600 transition-all duration-300"
              >
                {isSubmitting ? "Sending..." : "Send Message"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ContactUs;

import React, { useState } from "react";
import Slider from 'react-slick';
import './HappyClients.css'
import ReviewCard from "./ReviewCards";
import { motion } from "framer-motion";


const HappyClients = () => {

  // Sample data for carousel items
  const reviews = [
    {
      rating: "⭐⭐⭐⭐⭐",
      text: "It was a pleasure working with the Coca team. They understood the brief correctly and delivered great designs exceeding the expectations.",
      name: "Savannah Nguyen",
      position: "CEO Sans Brothers",
      img: "path_to_savannah_image", // Update with the correct path to the image
      imgSize: "40px",
    },
    {
      rating: "⭐⭐⭐⭐⭐",
      text: "Their attention to detail and creative approach made a significant impact on our project. Highly recommended!",
      name: "John Doe",
      position: "CTO Tech Solutions",
      img: "path_to_john_image", // Update with the correct path to the image
      imgSize: "40px",
    },
    {
      rating: "⭐⭐⭐⭐⭐",
      text: "Excellent service and support. The team was responsive and delivered high-quality work on time.",
      name: "Jane Smith",
      position: "Marketing Director at XYZ",
      img: "path_to_jane_image", // Update with the correct path to the image
      imgSize: "40px",
    },
    {
      rating: "⭐⭐⭐⭐⭐",
      text: "Excellent service and support. The team was responsive and delivered high-quality work on time.",
      name: "Jane Smith",
      position: "Marketing Director at XYZ",
      img: "path_to_jane_image", // Update with the correct path to the image
      imgSize: "40px",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    pauseOnHover: true,
    centerMode: true,
    centerPadding: '0px',
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    centerMode: true,
    centerPadding: "0px",
    arrows: true, // Enable arrows
    prevArrow: <div className="slick-prev"></div>,
    nextArrow: <div className="slick-next"></div>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,

        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false, // Disable center mode on smaller screens

        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          arrows: true,
        }
      }
    ]
  };


  return (
    <motion.section
      className="w-full my-8"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, amount: 0.2 }}
      transition={{ duration: 0.8, ease: "easeOut" }}
    >
      <div className="p-10 flex flex-col justify-center items-center max-sm:p-0">
        <motion.p
          className="custom-small-title text-slate-300 max-sm:text-sm"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          2123 people have said how good buddy
        </motion.p>
        <motion.h1
          className="landing-page-title-2 text-center-custom text-4xl text-slate-300 font-bold max-sm:text-2xl max-sm:text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
        >
          Reviews from our growing community
        </motion.h1>
        <div className="w-full my-8">
          <Slider {...settings} className="custom-slider">
            {reviews.map((review) => (
              <ReviewCard
                key={review.id} // Ensure each ReviewCard has a unique key prop
                rating={review.rating}
                text={review.text}
                name={review.name}
                position={review.position}
                imgSrc={review.imgSrc}
              />
            ))}
          </Slider>
        </div>
      </div>
    </motion.section>
  );
};

export default HappyClients;

import React from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import SVGSuper from "../../../assets/SVG/SVGSuper";
import { useNavigate } from 'react-router-dom';

const SuperHero = () => {
  const navigate = useNavigate();
  const controls = useAnimation();
  const ref2 = useInView({ triggerOnce: true, amount: 0.2 });

  // Define variants for animations
  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.2, // Staggers the animations for each child
      },
    },
  };

  const fadeUpVariant = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0, transition: { duration: 1.2, ease: "easeOut" } },
  };

  const parallaxVariant = {
    hidden: { opacity: 0, scale: 0.9, y: 100 },
    visible: { opacity: 1, scale: 1, y: 0, transition: { duration: 1, ease: "easeOut" } },
  };

  const buttonVariant = {
    rest: { scale: 1, rotate: 0, boxShadow: "0px 0px 20px rgba(97, 95, 176, 0.5)" },
    hover: {
      scale: 1.1,
      rotate: -2,
      boxShadow: "0px 0px 30px rgba(97, 95, 176, 0.7)",
      transition: { duration: 0.3 },
    },
  };

  const svgBounceVariant = {
    hidden: { opacity: 0, scale: 0.8, y: 30 },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 120,
        damping: 10,
        duration: 1.4,
      },
    },
  };

  return (
    <motion.section
      ref2={ref2}
      className="flex flex-col justify-center items-center relative transform"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.3 }}
      variants={containerVariants}
    >
      {/* Heading Animation */}
      <motion.h1
        className="landing-page-title-2 text-center-custom text-slate-300 text-4xl text-center font-bold leading-tight max-sm:text-2xl"
        variants={fadeUpVariant}
      >
        Discover your Superhero match
      </motion.h1>

      {/* Subtitle Animation */}
      <motion.p
        className="landing-subtitle text-slate-400 text-center my-4 max-sm:text-xs"
        variants={fadeUpVariant}
      >
        Upload your resume, hit run, and watch Buddy work its magic to find your ultimate superhero match: Ready to save your career in style!
      </motion.p>

      <div className="w-full my-12 h-auto flex mx-auto items-center justify-center">
        <motion.div
          className="shadow-sm w-[900px] h-auto relative flex justify-center items-center max-sm:w-full"
          variants={parallaxVariant}
        >
          <motion.div
            className="w-full p-0 z-10 bg-[#101217] relative text-slate-300 rounded-xl flex flex-col overflow-hidden max-sm:w-[95%] max-sm:mx-auto"
            variants={fadeUpVariant}
          >
            <div className="w-full h-[50px] bg-gradient-to-r from-[#413E8B] to-slate-900"></div>
            <div className="flex p-14 max-sm:flex-col max-sm:p-7">
              <motion.div className="flex flex-col justify-between flex-1" variants={fadeUpVariant}>
                <div className="flex flex-col">
                  <motion.h4
                    className="custom-sub-title text-3xl font-bold text-slate-200 max-sm:text-xl"
                    variants={fadeUpVariant}
                  >
                    Super Buddy
                  </motion.h4>
                  <motion.p
                    className="custom-small-title text-left my-2 text-[#E9E9E9] lg:w-[75%] max-sm:text-sm"
                    variants={fadeUpVariant}
                  >
                    What is your typical process when starting a new UI design project? How do you gather requirements, create wiref2rames, and finalize the design?
                  </motion.p>
                </div>
                <motion.p className="custom-small-title font-bold max-sm:text-sm" variants={fadeUpVariant}>
                  *This template already ran <span className="font-bold">22,879</span> times.
                </motion.p>
              </motion.div>

              {/* SVG Animation */}
              <motion.div
                className="flex flex-col items-center mt-8 lg:mt-0 lg:ml-8"
                variants={svgBounceVariant}
              >
                <SVGSuper className="w-48 h-48" />
                <motion.button
                  onClick={() => navigate('/templates')}
                  className="w-[70%] relative mt-6 py-2 px-12 bg-gradient-to-r from-indigo-400 to-transparent ring-[#615fb0] ring-1 text-slate-300 rounded-3xl shadow-[0_0_50px_10px_rgba(97,95,176,0.5)]"
                  variants={buttonVariant}
                  initial="rest"
                  whileHover="hover"
                >
                  Run
                  <div
                    className="absolute inset-0 bg-transparent z-[-1] rounded-full"
                    style={{
                      background: "radial-gradient(circle, rgba(119,116,213,0.3) 0%, rgba(16,18,23,0) 100%)",
                      backdropFilter: "blur(20px)",
                    }}
                  ></div>
                </motion.button>
              </motion.div>
            </div>
          </motion.div>
          <motion.div
            className="absolute inset-0 bg-gradient-to-r from-[#00FFEE] via-[#413E8B] to-[#FF00FF] opacity-50 blur-xl z-0"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            transition={{ duration: 1.5 }}
          ></motion.div>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default SuperHero;

import React from "react";
import { useInView } from "react-intersection-observer";

const Section = ({ children }) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.2 });
  return (
    <div
      ref={ref}
      className={`transition-opacity duration-700 transform ${
        inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
      }`}
    >
      {children}
    </div>
  );
};

const TermsAndPrivacy = () => {
  return (
    <div className="max-w-[1340px] !pt-12  mx-auto py-16 px-6 lg:px-8 flex flex-col items-center">
      <div className="w-full max-w-[1000px] mx-auto">
        {/* Header Section */}
        <Section>
          <h1 className="text-[26px] font-bold text-center mb-4 sm:text-[36px] bg-gradient-to-r from-[#E1E1E1] to-[#929292] bg-clip-text text-transparent">
            Our Terms of Service and Privacy Policy
          </h1>
          <p className="text-center text-[#b1b1b1] dark:text-[#d1d1d1] text-[16px] mb-12">
            Please read our Terms and Conditions and Privacy Policy carefully before using our services.
          </p>
        </Section>

        {/* Terms and Conditions Section */}
        <Section>
          <h2 className="text-[22px] font-semibold text-white mb-6">Terms and Conditions</h2>
          <div className="mb-8 p-6 bg-[#1e1e2a] bg-opacity-60 backdrop-blur-lg backdrop-saturate-150 rounded-xl shadow-md">
            <h3 className="text-[20px] font-semibold text-white mb-4">1. Acceptance of Terms</h3>
            <p className="text-[#d1d1d1] text-[16px] leading-relaxed">
              By using the App, you acknowledge that you have read, understood, and agree to be bound by these Terms, as well as our Privacy Policy, which is incorporated by reference into these Terms.
            </p>
          </div>

          <div className="mb-8 p-6 bg-[#1e1e2a] bg-opacity-60 backdrop-blur-lg backdrop-saturate-150 rounded-xl shadow-md">
            <h3 className="text-[20px] font-semibold text-white mb-4">2. Use of the App</h3>
            <p className="text-[#d1d1d1] text-[16px] leading-relaxed">
              2.1 Eligibility: You must be at least 18 years of age or the legal age of majority in your jurisdiction to use the App.<br/>
              2.2 User Content: You are solely responsible for the content you upload to the App, including your CV and job description. You agree not to upload any content that violates applicable laws, infringes on the rights of others, or is harmful or offensive.<br/>
              2.3 Algorithm Recommendations: We do not guarantee the accuracy or effectiveness of the recommendations provided by our algorithm. The recommendations are for informational purposes only, and you should exercise your own judgment when implementing them.<br/>
              2.4 Availability: We do not guarantee that the App will be available at all times, and we may suspend or terminate access to the App without prior notice.
            </p>
          </div>

          <div className="mb-8 p-6 bg-[#1e1e2a] bg-opacity-60 backdrop-blur-lg backdrop-saturate-150 rounded-xl shadow-md">
            <h3 className="text-[20px] font-semibold text-white mb-4">3. Intellectual Property</h3>
            <p className="text-[#d1d1d1] text-[16px] leading-relaxed">
              3.1 Ownership: The App and all related content are owned by the Company or its licensors and are protected by intellectual property laws.<br/>
              3.2 License: We grant you a limited, non-exclusive, non-transferable, and revocable license to use the App for its intended purposes.
            </p>
          </div>

          <div className="mb-8 p-6 bg-[#1e1e2a] bg-opacity-60 backdrop-blur-lg backdrop-saturate-150 rounded-xl shadow-md">
            <h3 className="text-[20px] font-semibold text-white mb-4">4. Privacy</h3>
            <p className="text-[#d1d1d1] text-[16px] leading-relaxed">
              Your use of the App is subject to our Privacy Policy, which outlines how we collect, use, and disclose your personal information.
            </p>
          </div>
          
          <div className="mb-8 p-6 bg-[#1e1e2a] bg-opacity-60 backdrop-blur-lg backdrop-saturate-150 rounded-xl shadow-md">
            <h3 className="text-[20px] font-semibold text-white mb-4">5. Disclaimers and Limitations of Liability</h3>
            <p className="text-[#d1d1d1] text-[16px] leading-relaxed">
              5.1 No Warranty: The App is provided "As is" without any warranties, express or implied.<br/>
              5.2 Limitation of Liability: To the fullest extent permitted by law, the company shall not be liable for any indirect, incidental, consequential, or punitive damages.
            </p>
          </div>

          <div className="mb-8 p-6 bg-[#1e1e2a] bg-opacity-60 backdrop-blur-lg backdrop-saturate-150 rounded-xl shadow-md">
            <h3 className="text-[20px] font-semibold text-white mb-4">6. Termination</h3>
            <p className="text-[#d1d1d1] text-[16px] leading-relaxed">
              We reserve the right to terminate your access to the App at our sole discretion, with or without cause, and without prior notice.
            </p>
          </div>
        </Section>

        {/* Privacy Policy Section */}
        <Section>
          <h2 className="text-[22px] font-semibold text-white mb-6 mt-12">Privacy Policy</h2>

          <div className="mb-8 p-6 bg-[#1e1e2a] bg-opacity-60 backdrop-blur-lg backdrop-saturate-150 rounded-xl shadow-md">
            <h3 className="text-[20px] font-semibold text-white mb-4">1. Information We Collect</h3>
            <p className="text-[#d1d1d1] text-[16px] leading-relaxed">
              We may collect personal information that you provide voluntarily, including your name, email address, CV, and job description.
            </p>
          </div>

          <div className="mb-8 p-6 bg-[#1e1e2a] bg-opacity-60 backdrop-blur-lg backdrop-saturate-150 rounded-xl shadow-md">
            <h3 className="text-[20px] font-semibold text-white mb-4">2. How We Use Your Information</h3>
            <p className="text-[#d1d1d1] text-[16px] leading-relaxed">
              We use your personal information to provide you with services offered by the App, such as generating recommendations for optimizing your CV. Additionally, we may use your information to improve the functionality and features of the App and to conduct research and analysis.
            </p>
          </div>
        </Section>

        {/* Contact Section */}
        <Section>
          <div className="mb-8 p-6 bg-[#1e1e2a] bg-opacity-60 backdrop-blur-lg backdrop-saturate-150 rounded-xl shadow-md">
            <h3 className="text-[20px] font-semibold text-white mb-4">Contact Us</h3>
            <p className="text-[#d1d1d1] text-[16px] leading-relaxed">
              If you have any questions about these Terms or our Privacy Policy, please contact us at <span className="text-indigo-400">business@faanginc.com</span>.
            </p>
          </div>
        </Section>
      </div>
    </div>
  );
};

export default TermsAndPrivacy;

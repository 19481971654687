import React, { useEffect, useRef, useState } from "react";
import { useInView } from 'react-intersection-observer';
import bgGlow from '../../../assets/bg-glow2.png'
import SVGEarth from "../../../assets/SVG/SVGEarth";
import BuddyRocket from "../../../assets/TemplatesIcon/buddy_rocket.png";
import { useNavigate } from "react-router-dom";

const templates = [
  {
    img: BuddyRocket,
    title: "Superhero Buddy",
    description:
      "Hey there, sidekick! I’m Superhero Buddy, your trusty guide to discovering your inner hero! Upload your resume, and I’ll reveal which superhero you are and why you’re destined for greatness! Ready to unleash your powers? 🚀",
    runs: 22879,
    templatelink: '/templates/superherobuddy',
  },
  {
    img: SVGEarth,
    title: "CV Booster Buddy",
    description:
      "Hi there! I’m CV Booster Buddy, ready to sprinkle some magic on your resume. Just upload your CV and your dream job link, and I’ll provide tailored feedback to help you get that interview. Let’s make your resume shine! 🧙‍♂️",
    runs: 22879,
    templatelink: '/templates/cvboosterbuddy',
  },

// sample
  {
    img: SVGEarth,
    title: "CV Booster Buddy",
    description:
      "Hi there! I’m CV Booster Buddy, ready to sprinkle some magic on your resume. Just upload your CV and your dream job link, and I’ll provide tailored feedback to help you get that interview. Let’s make your resume shine! 🧙‍♂️",
    runs: 22879,
    templatelink: '/templates/cvboosterbuddy',
  },
]
const HomeTemplates = () => {
  const [currentCard, setCurrentCard] = useState(0);
  const scrollRef = useRef(null);
  
  const navigate = useNavigate()
  // Using useInView to trigger animation when section comes into view
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger animation only once
    threshold: 0.50,    // When 20% of the section is visible
  });

  const handleScroll = () => {
    const scrollLeft = scrollRef.current.scrollLeft;
    const width = scrollRef.current.clientWidth;
    const index = Math.round(scrollLeft / width);
    console.log("index",index)
    console.log("width",width)
    setCurrentCard(index);
  };

  useEffect(() => {
    const ref = scrollRef.current;
    
    if (ref) {
      ref.addEventListener("scroll", handleScroll);
    }
    
    return () => {
      if (ref) {
        ref.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const dotslength = templates.length / 2.5;

  return (
    <section
      ref={ref}
      className={`flex flex-col justify-center items-center relative p-4 transition-all duration-700 ${
        inView ? 'animate-fade-in-up' : 'opacity-0'
      }`} // Add animation class when in view
    >
      <img style={{position:"absolute", opacity:"0.2",top:"-12%"}} src={bgGlow}/>
      <h1 className="landing-page-title-2 text-center-custom text-center text-slate-300 text-4xl font-bold leading-tight sm:text-3xl xs:text-2xl">
        {/* Try Out Top 10 Templates upvoted from our customers */}
        Try our top x Templates upvoted by our community
      </h1>
      <div
        ref={scrollRef}
        className="flex w-full space-x-4 my-10 overflow-x-auto scrollbar-hide snap-x snap-mandatory"
      >
        {templates.map((item, index) => (
          <div
            key={index}
            style={{
              background: "radial-gradient(112.32% 83.8% at 50.41% 50%, rgba(0, 0, 0, 0) 0%, rgba(152, 195, 189, 0.1) 100%)"
            }}
            className={`rounded-[30px] border border-white/[0.08] bg-black/20 backdrop-blur-[4px] w-[300px] sm:w-[350px] md:w-[400px] lg:w-[410px] h-auto bg-[#101217] rounded-lg overflow-hidden flex-shrink-0 shadow-lg snap-center transition-transform duration-700 ease-out ${
              inView ? 'animate-slideIn' : 'opacity-0'
            }`} // Animating each card
          >
            {/* <div className="w-full h-6 bg-gradient-to-r from-[#413E8B] to-slate-900"></div> */}
            <div className="pt-6 pb-4 px-4 sm:px-6 md:px-8 w-full h-auto flex flex-col justify-between">
              <div className="flex flex-col">
                <h4 className="text-lg font-bold text-slate-50">
                  {/* Superbuddy */}
                  {item.title}
                  </h4>
                <p className="buddyText text-slate-500 text-sm my-4">
                  {/* What is your typical process when starting a new UI design
                  project? How do you gather requirements, create wireframes, and
                  finalize the design? What is your typical process when starting a
                  new UI design project? */}
                  {
                    item.description
                  }
                </p>
              </div>
              <div className="flex justify-between items-center my-4 space-x-2">
                <span className="buddyText text-slate-500 text-sm">
                  *This template is run {item.runs} times.
                </span>
                <div onClick={() => navigate(item.templatelink)} className="button-3 w-fit cursor-pointer bg-gradient-to-r from-indigo-400 to-transparent bg-transparent ring-[#615fb0] ring-1 relative py-2 px-4 sm:py-2 sm:px-6 text-slate-300 rounded-full flex justify-center items-center shadow-md hover:shadow-lg">
                  Run
                  <div
                    className="absolute top-[60%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 rounded-full"
                    style={{
                      background:
                        "radial-gradient(circle, rgba(119,116,213,0.3) 0%, rgba(16,18,23,0) 100%)",
                      boxShadow: "0px 0px 100px 10px rgba(119,116,213,0.4)",
                      backdropFilter: "blur(20px)",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Dots Navigation */}
      <div className="flex space-x-2 mt-4">
        {Array.from({ length: dotslength }).map((_, index) => (
          <span
            key={index}
            className={`h-2 w-2 rounded-full ${
              currentCard === index ? "bg-slate-300" : "bg-slate-500"
            }`}
          />
        ))}
      </div>

      <div onClick={() => navigate('/templates')} className="button-4 w-fit mt-8 cursor-pointer bg-gradient-to-r from-indigo-400 to-transparent bg-transparent ring-[#615fb0] ring-1 relative py-4 px-6 sm:py-5 sm:px-8 text-slate-300 rounded-full flex justify-center items-center shadow-md hover:shadow-lg">
        Explore more
        <div
          className="absolute top-[60%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 rounded-full"
          style={{
            background:
              "radial-gradient(circle, rgba(119,116,213,0.3) 0%, rgba(16,18,23,0) 100%)",
            boxShadow: "0px 0px 100px 10px rgba(119,116,213,0.4)",
            backdropFilter: "blur(20px)",
          }}
        ></div>
      </div>
    </section>
  );
};

export default HomeTemplates;



// import React, { useEffect, useRef, useState } from "react";
// import { useInView } from 'react-intersection-observer';
// import bgGlow from '../../../assets/bg-glow2.png'
// import SVGEarth from "../../../assets/SVG/SVGEarth";
// import BuddyRocket from "../../../assets/TemplatesIcon/buddy_rocket.png";

// const templates = [
//   {
//     img: BuddyRocket,
//     title: "Superhero Buddy",
//     description:
//       "Hey there, sidekick! I’m Superhero Buddy, your trusty guide to discovering your inner hero! Upload your resume, and I’ll reveal which superhero you are and why you’re destined for greatness! Ready to unleash your powers? 🚀",
//     runs: 22879,
//   },
//   {
//     img: SVGEarth,
//     title: "CV Booster Buddy",
//     description:
//       "Hi there! I’m CV Booster Buddy, ready to sprinkle some magic on your resume. Just upload your CV and your dream job link, and I’ll provide tailored feedback to help you get that interview. Let’s make your resume shine! 🧙‍♂️",
//     runs: 22879,
//   },

// // sample
//   {
//     img: SVGEarth,
//     title: "CV Booster Buddy",
//     description:
//       "Hi there! I’m CV Booster Buddy, ready to sprinkle some magic on your resume. Just upload your CV and your dream job link, and I’ll provide tailored feedback to help you get that interview. Let’s make your resume shine! 🧙‍♂️",
//     runs: 22879,
//   },
// ]
// const HomeTemplates = () => {
//   const [currentCard, setCurrentCard] = useState(0);
//   const scrollRef = useRef(null);
  
//   // Using useInView to trigger animation when section comes into view
//   const { ref, inView } = useInView({
//     triggerOnce: true, // Trigger animation only once
//     threshold: 0.2,    // When 20% of the section is visible
//   });

//   const handleScroll = () => {
//     const scrollLeft = scrollRef.current.scrollLeft;
//     const width = scrollRef.current.clientWidth;
//     const index = Math.round(scrollLeft / width);
//     setCurrentCard(index);
//   };

//   useEffect(() => {
//     const ref = scrollRef.current;
    
//     if (ref) {
//       ref.addEventListener("scroll", handleScroll);
//     }
    
//     return () => {
//       if (ref) {
//         ref.removeEventListener("scroll", handleScroll);
//       }
//     };
//   }, []);

//   return (
//     <section
//       ref={ref}
//       className={`flex flex-col justify-center items-center relative p-4 transition-all duration-700 ${
//         inView ? 'animate-fade-in-up' : 'opacity-0'
//       }`} // Add animation class when in view
//     >
//       <img style={{position:"absolute", opacity:"0.5",top:"-12%"}} src={bgGlow}/>
//       <h1 className="landing-page-title-2 text-center-custom text-center text-slate-300 text-4xl font-bold leading-tight sm:text-3xl xs:text-2xl">
//         {/* Try Out Top 10 Templates upvoted from our customers */}
//         Try our top x Templates upvoted by our community
//       </h1>
//       <div
//         ref={scrollRef}
//         className="flex w-full space-x-4 my-10 overflow-x-auto scrollbar-hide snap-x snap-mandatory"
//       >
//         {templates.map((_, index) => (
//           <div
//             key={index}
//             style={{
//               background: "radial-gradient(112.32% 83.8% at 50.41% 50%, rgba(0, 0, 0, 0) 0%, rgba(152, 195, 189, 0.1) 100%)"
//             }}
//             className={`rounded-[30px] border border-white/[0.08] bg-black/20 backdrop-blur-[4px] w-[300px] sm:w-[350px] md:w-[400px] lg:w-[410px] h-auto bg-[#101217] rounded-lg overflow-hidden flex-shrink-0 shadow-lg snap-center transition-transform duration-700 ease-out ${
//               inView ? 'animate-slideIn' : 'opacity-0'
//             }`} // Animating each card
//           >
//             {/* <div className="w-full h-6 bg-gradient-to-r from-[#413E8B] to-slate-900"></div> */}
//             <div className="pt-6 pb-4 px-4 sm:px-6 md:px-8 w-full h-auto flex flex-col justify-between">
//               <div className="flex flex-col">
//                 <h4 className="text-lg font-bold text-slate-50">Superbuddy</h4>
//                 <p className="buddyText text-slate-500 text-sm my-4">
//                   What is your typical process when starting a new UI design
//                   project? How do you gather requirements, create wireframes, and
//                   finalize the design? What is your typical process when starting a
//                   new UI design project?
//                 </p>
//               </div>
//               <div className="flex justify-between items-center my-4 space-x-2">
//                 <span className="buddyText text-slate-500 text-sm">
//                   *This template is run 200 times.
//                 </span>
//                 <div className="button-3 w-fit cursor-pointer bg-gradient-to-r from-indigo-400 to-transparent bg-transparent ring-[#615fb0] ring-1 relative py-2 px-4 sm:py-2 sm:px-6 text-slate-300 rounded-full flex justify-center items-center shadow-md hover:shadow-lg">
//                   Run
//                   <div
//                     className="absolute top-[60%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 rounded-full"
//                     style={{
//                       background:
//                         "radial-gradient(circle, rgba(119,116,213,0.3) 0%, rgba(16,18,23,0) 100%)",
//                       boxShadow: "0px 0px 100px 10px rgba(119,116,213,0.4)",
//                       backdropFilter: "blur(20px)",
//                     }}
//                   ></div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>

//       {/* Dots Navigation */}
//       <div className="flex space-x-2 mt-4">
//         {[...Array(3)].map((_, index) => (
//           <span
//             key={index}
//             className={`h-2 w-2 rounded-full ${
//               currentCard === index ? "bg-slate-300" : "bg-slate-500"
//             }`}
//           />
//         ))}
//       </div>

//       <div className="button-4 w-fit mt-8 cursor-pointer bg-gradient-to-r from-indigo-400 to-transparent bg-transparent ring-[#615fb0] ring-1 relative py-4 px-6 sm:py-5 sm:px-8 text-slate-300 rounded-full flex justify-center items-center shadow-md hover:shadow-lg">
//         Explore more
//         <div
//           className="absolute top-[60%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 rounded-full"
//           style={{
//             background:
//               "radial-gradient(circle, rgba(119,116,213,0.3) 0%, rgba(16,18,23,0) 100%)",
//             boxShadow: "0px 0px 100px 10px rgba(119,116,213,0.4)",
//             backdropFilter: "blur(20px)",
//           }}
//         ></div>
//       </div>
//     </section>
//   );
// };

// export default HomeTemplates;

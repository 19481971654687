import React from "react";

const SVGFB = () => {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_131_1266)">
        <path
          d="M28 14.7468C28 7.01491 21.7319 0.746826 14 0.746826C6.26808 0.746826 0 7.01491 0 14.7468C0 21.3123 4.52032 26.8215 10.6182 28.3347V19.0252H7.73136V14.7468H10.6182V12.9033C10.6182 8.13827 12.7747 5.92963 17.453 5.92963C18.34 5.92963 19.8705 6.10379 20.4966 6.27739V10.1554C20.1662 10.1207 19.5922 10.1033 18.8793 10.1033C16.5838 10.1033 15.6968 10.973 15.6968 13.2337V14.7468H20.2698L19.4841 19.0252H15.6968V28.6443C22.629 27.8071 28.0006 21.9047 28.0006 14.7468H28Z"
          fill="white"
        />
        <path
          d="M19.4836 19.0253L20.2692 14.7469H15.6963V13.2338C15.6963 10.973 16.5833 10.1034 18.8788 10.1034C19.5916 10.1034 20.1656 10.1207 20.496 10.1554V6.27745C19.87 6.10329 18.3395 5.92969 17.4524 5.92969C12.7742 5.92969 10.6176 8.13833 10.6176 12.9034V14.7469H7.73083V19.0253H10.6176V28.3347C11.7007 28.6035 12.8336 28.7469 13.9995 28.7469C14.5735 28.7469 15.1396 28.7116 15.6957 28.6444V19.0253H19.483H19.4836Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_131_1266">
          <rect
            width="28"
            height="28"
            fill="white"
            transform="translate(0 0.746826)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SVGFB;

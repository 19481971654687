import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion"; // Import framer-motion for animations
import SVGRocket from "../../assets/SVG/SVGRocket";
import SVGEarth from "../../assets/SVG/SVGEarth";
import BuddyRocket from "../../assets/TemplatesIcon/buddy_rocket.png";
import cv_booster from "../../assets/TemplatesIcon/cv_booster.png";

const slugify = (text) => {
  return text.toLowerCase().replace(/[^a-z0-9]+/g, "");
};

const Templates = () => {
  const [selectedBuddy, setSelectedBuddy] = useState(0);
  const navigate = useNavigate();

  const templates = [
    {
      img: BuddyRocket,
      title: "Superhero Buddy",
      description:
        "Hey there, sidekick! I’m Superhero Buddy, your trusty guide to discovering your inner hero! Upload your resume, and I’ll reveal which superhero you are and why you’re destined for greatness! Ready to unleash your powers? 🚀",
      runs: 22879,
    },
    {
      img: SVGEarth,
      title: "CV Booster Buddy",
      description:
        "Hi there! I’m CV Booster Buddy, ready to sprinkle some magic on your resume. Just upload your CV and your dream job link, and I’ll provide tailored feedback to help you get that interview. Let’s make your resume shine! 🧙‍♂️",
      runs: 22879,
    },
  ];

  const handleRunTemplate = (templateTitle) => {
    const slug = slugify(templateTitle);
    navigate(`/templates/${slug}`);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, ease: "easeOut" }}
      className="max-w-[1340px] h-auto mx-auto flex flex-col max-sm:p-[10px]"
    >
      <div className="lg:w-[1038px] my-[50px] mx-auto flex flex-col gap-[50px] items-center">
        <div className="lg:w-[786px] flex flex-col gap-[40px] items-center">
          <h1 className="text-[22px] font-bold text-center sm:text-[40px] bg-gradient-to-r from-[#E1E1E1] to-[#929292] bg-clip-text text-transparent">
            Try out our top 10 Buddy templates upvoted by our community
          </h1>
        </div>

        <div className="flex flex-wrap gap-[28px] justify-center px-[30px]">
          {templates.map((template, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="flex flex-col gap-[48px] p-[20px] md:p-[32px] bg-[#7A77CC1A] rounded-[12px] w-[300px] md:w-[470px]"
            >
              <div className="flex flex-col gap-[20px]">
                {typeof template.img === "string" ? (
                  <img
                    style={{ width: "90px" }}
                    src={template.img}
                    alt="Buddy"
                  />
                ) : (
                  <template.img />
                )}
                <h1 className="text-[18px] text-white">{template.title}</h1>
                <p className="text-[15px] text-[#E9E9E9B2]">
                  {template.description}
                </p>
              </div>
              <div className="flex justify-between items-center gap-[15px]">
                <p className="text-[#E9E9E9CC] text-[12px] sm:text-[15px]">
                  *This template already ran {template.runs} times.
                </p>
                <div
                  onClick={() => handleRunTemplate(template.title)}
                  className="button-1 cursor-pointer bg-transparent ring-1 z-10 ring-[#615fb0] relative py-4 px-10 text-slate-300 rounded-30 flex justify-center items-center shadow-[0_0_50px_rgba(97,95,176,0.5)] hover:shadow-[0_0_50px_10px_rgba(97,95,176,0.5)] max-sm:py-[10px] max-sm:px-[15px] max-sm:text-xs"
                >
                  Run
                  <div
                    className="bg-transparent z-30 rounded-30 absolute top-[60%] left-[50%] transform -translate-x-1/2 -translate-y-1/2"
                    style={{
                      background:
                        "radial-gradient(circle, rgba(119,116,213,0.3) 0%, rgba(16,18,23,0) 100%)",
                      boxShadow: "0px 0px 100px 10px rgba(119,116,213,0.4)",
                      backdropFilter: "blur(20px)",
                    }}
                  ></div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        <div className="button-4 w-fit mt-8 cursor-pointer bg-gradient-to-r from-indigo-400 to-transparent ring-[#615fb0] ring-1 relative py-4 px-6 sm:py-5 sm:px-8 text-slate-300 rounded-full flex justify-center items-center shadow-md hover:shadow-lg">
          Explore more
          <div
            className="absolute top-[60%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 rounded-full"
            style={{
              background:
                "radial-gradient(circle, rgba(119,116,213,0.3) 0%, rgba(16,18,23,0) 100%)",
              boxShadow: "0px 0px 100px 10px rgba(119,116,213,0.4)",
              backdropFilter: "blur(20px)",
            }}
          ></div>
        </div>
      </div>
    </motion.div>
  );
};

export default Templates;

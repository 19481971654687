// src/Pages/ErrorPage/ErrorPage.jsx
import React from 'react';
import { Link } from 'react-router-dom';

const ErrorPage = () => {
  return (
    <div className="pt-16 pb-16 flex flex-col items-center justify-center h-[100%] text-center  text-gray-300">
      <h1 className="text-[100px] font-extrabold text-[#6460cf] mb-4 animate-pulse">404</h1>
      <h2 className="text-4xl font-semibold mb-2 text-white">Oops! Page Not Found</h2>
      <p className="text-lg mb-8 max-w-md text-gray-400">
        The page you’re looking for doesn’t exist. It might have been moved or deleted.
      </p>
      <Link
        to="/"
        className="bg-indigo-600 text-white py-3 px-8 rounded-full font-semibold hover:bg-indigo-500 transition-colors"
        >
        Return to Home
      </Link>
    </div>
  );
};

export default ErrorPage;

import React from "react";

const SVGX = () => {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.3807 2.96802H25.3164L16.718 12.7954L26.8334 26.1683H18.9131L12.7097 18.0577L5.61163 26.1683H1.67352L10.8704 15.6568L1.16669 2.96802H9.28798L14.8953 10.3814L21.3807 2.96802ZM19.9994 23.8126H22.1802L8.10298 5.20001H5.76273L19.9994 23.8126Z"
        fill="white"
      />
    </svg>
  );
};

export default SVGX;

import { useLocation } from "react-router-dom"; // Import useLocation for accessing location state
import { useEffect, useState } from "react";
import SuperHeroBuddy from "../TemplatesType/SuperHeroBuddy";
import CVBoosterBuddyFeedback from "../TemplatesType/CVBoosterBuddy";
// import CvBooster from "../TemplatesType/CvBooster"; 

import { GiphyFetch } from '@giphy/js-fetch-api';


const gf = new GiphyFetch('GNtZzNEZ05WNIX6OFYuXbAvMRVO5Kc3u');

const TemplatesProcessing = () => {
    const location = useLocation(); // Get location from useLocation
    const { templateType, response } = location.state || {};

    const imgGif = response.superhero_match.superhero_name

    const [gifUrl, setGifUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  const [staticImageUrl, setStaticImageUrl] = useState(null);

  const fetchGif = async () => {
    setLoading(true);
    try {
      const result = await gf.search(imgGif, { limit: 10, rating: 'pg' }); // Fetch 1 GIF
      console.log("result",result)
      if (result.data.length > 0) {
        setGifUrl(result.data[2].images.original.url); // Extract the first GIF's URL
        setStaticImageUrl(result.data[2].images.downsized_still.url);
      } else {
        setGifUrl(null); // Handle case if no GIF is found
        setStaticImageUrl(null);
      }
    } catch (error) {
      console.error('Error fetching GIF:', error);
    }
    setLoading(false);
  };



  useEffect(() => {
    fetchGif(); // Fetch GIF when the component loads or query changes
    console.log("gifUrl",gifUrl)
    console.log("staticImageUrl",staticImageUrl)
  }, [response.superhero_match.superhero_name]);

    if (templateType === "superherobuddy") {
        return <SuperHeroBuddy superheroData={response} gifUrl={gifUrl} staticImageUrl={staticImageUrl}/>;
    }


    if (templateType === "cvboosterbuddy") {
        return <CVBoosterBuddyFeedback feedbackData={response} gifUrl={gifUrl}/>;
    }

    return null; // Handle case where templateType does not match
};

export default TemplatesProcessing;

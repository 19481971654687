import { useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import SVGUpload from "../../assets/SVG/SVGUpload";
import BuddyRocket from "../../assets/TemplatesIcon/buddy_rocket.png";
import { FaFilePdf, FaTrashAlt } from "react-icons/fa";
import SVGEarth from "../../assets/SVG/SVGEarth";
import { TemplateFunction } from "../../ApiEnpoint/ApiEndpoints";
import SuperHeroBuddy from "./TemplatesType/SuperHeroBuddy";

export default function EachTemplate() {
    const fileRef = useRef();
    const { templateId } = useParams(); // Fetching the templateId from the URL
    const [uploadedFile, setUploadedFile] = useState(null); // To store the uploaded file
    const [jobDescription, setJobDescription] = useState(""); // To store the job description
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate(); // Hook to navigate programmatically


    const handleInputClick = () => {
        if (fileRef.current) {
            fileRef.current.click();
        }
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file && file.type === "application/pdf") {
            setUploadedFile(file);
            setError(null); // Clear previous errors
        } else {
            setError("Only PDF files are supported.");
        }
    };

    const handleRunButton = async () => {
        if (!uploadedFile) {
            setError("Please upload a PDF file.");
            return;
        }

        setLoading(true);
        setError(null); // Clear previous errors
        try {
            const data = await TemplateFunction(uploadedFile, templateId, jobDescription);
            console.log("data", data)
            setResponse(data);
            const templateType = data.PK
            console.log("navigated", `/templates/${templateId}/${data.ID}`)
            navigate(`/templates/${templateId}/${data.id}`, {
                state: { templateType: templateType, response: data }, // Sending data in state
            });

            // No need to return anything from here
        } catch (err) {
            console.error("There was an error uploading the file:", err);
            setError("There was an error processing your request. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const handleRemoveFile = () => {
        setUploadedFile(null);
        setError(null); // Clear errors if any
    };

    const renderTemplateContent = () => {
        if (templateId === "superherobuddy") {
            return (
                <>
                    <h4 className="lg:w-[713px] font-bold text-center text-2xl bg-gradient-to-r from-[#E1E1E1] to-[#929292] bg-clip-text text-transparent">
                        Hey there, sidekick! I’m SuperBuddy!
                    </h4>
                    <img
                        style={{ width: "50px", marginTop: "10px" }}
                        src={BuddyRocket}
                        alt="Buddy"
                    />
                    {/* <p className="text-white text-center text-base">
                        Upload your resume, and I’ll match you with your superhero alter ego!
                    </p> */}
                </>
            );
        }

        if (templateId === "cvboosterbuddy") {
            return (
                <>
                    <h1 className="lg:w-[713px] font-bold text-center text-[22px] sm:text-[32px] lg:text-[40px] bg-gradient-to-r from-[#E1E1E1] to-[#929292] bg-clip-text text-transparent">
                        Hey, job seeker! I’m CV Booster Buddy!
                    </h1>
                    <div style={{ width: "100px", marginBottom: "20px", marginTop: "10px" }}>
                        <SVGEarth />
                    </div>
                    
                </>
            );
        }

        return (
            <>
                <h1 className="lg:w-[713px] font-bold text-center text-[22px] sm:text-[32px] lg:text-[40px] bg-gradient-to-r from-[#E1E1E1] to-[#929292] bg-clip-text text-transparent">
                    Try all the models for a single question
                </h1>
                <p className="text-white text-center text-[15px] sm:text-[18px] lg:text-[22px]">
                    This WordApp allows you to try all models on a single question and evaluate the answers.
                </p>
            </>
        );
    };

    const isSuperheroBuddy = templateId === "superherobuddy";
    const isCVBoosterBuddy = templateId === "cvboosterbuddy";

    // // If response is available and matches certain conditions, render accordingly
    // if (response) {
    //     if (response.PK === "superherobuddy") {
    //         return (<SuperHeroBuddy superheroData={response} />);
    //     }
    //     // Handle other response types if needed
    // }

    // // Now proceed to render the main component

    // #7A77CC1A
    return (
        <div className="w-full h-auto">
            <div style={{background:"radial-gradient(112.32% 83.8% at 50.41% 50%, rgba(0, 0, 0, 0) 0%, rgba(152, 195, 189, 0.1) 100%)"}} 
            className="w-[80%] lg:w-[1000px] my-[20px] mx-auto flex flex-col gap-[20px] items-center rounded-[20px] p-[10px] sm:p-[30px] lg:p-[40px]">
                {/* Render the template-specific content */}
                <div className="lg:w-[748px] flex flex-col gap-[10px] items-center">
                    {renderTemplateContent()}
                </div>

                <div className="flex flex-col gap-[15px] w-[100%]">
                    {isSuperheroBuddy ? (
                        <>
                            <p className="text-slate-50 text-base">Here's how the Superhero Buddy works:</p>
                            <div className="flex justify-between items-center gap-4">
                                <div className="flex flex-col ring-1 ring-[#312f70] rounded-3xl py-2 px-4">
                                    <h1 className="text-white text-sm">Step 1 : Upload Resume</h1>
                                    <p className="text-slate-500 text-sm my-2">
                                        Upload your resume in PDF format to reveal your superhero alter ego.
                                    </p>
                                </div>
                                <div className="flex flex-col ring-1 ring-[#312f70] rounded-3xl py-2 px-4">
                                    <h1 className="text-white text-sm">Step 2 : Superhero Match</h1>
                                    <p className="text-slate-500 text-sm my-2">
                                        Get a superhero name, a meme, and a backstory based on your resume!
                                    </p>
                                </div>
                            </div>
                        </>
                    ) : isCVBoosterBuddy ? (
                        <>
                            <p className="text-[#FFFFFFB2] text-[15px]">Here's how the CV Booster Buddy works:</p>
                            <div className="flex flex-col gap-[8px]">
                                <h1 className="text-white">Step 1: Upload Resume</h1>
                                <p className="text-[#FFFFFFB2] text-[12px] sm:text-[15px]">
                                    Upload your resume in PDF format.
                                </p>
                            </div>
                            <div className="flex flex-col gap-[8px]">
                                <h1 className="text-white">Step 2: Provide Job Description</h1>
                                <p className="text-[#FFFFFFB2] text-[12px] sm:text-[15px]">
                                    Paste the job description for the role you're applying to.
                                </p>
                            </div>
                            <div className="flex flex-col gap-[8px]">
                                <h1 className="text-white">Step 3: Get Feedback</h1>
                                <p className="text-[#FFFFFFB2] text-[12px] sm:text-[15px]">
                                    Receive tailored feedback to improve your CV based on the job requirements.
                                </p>
                            </div>
                        </>
                    ) : (
                        <>
                            <p className="text-[#FFFFFFB2] text-[15px]">This program does the following things:</p>
                            <div className="flex flex-col gap-[8px]">
                                <h1 className="text-white">1. Question Processing:</h1>
                                <p className="text-[#FFFFFFB2] text-[12px] sm:text-[15px]">
                                    Runs a question through various AI models, including GPT-4, Mistral, Claude 3, LLAMA 3, etc.
                                </p>
                            </div>
                            <div className="flex flex-col gap-[8px]">
                                <h1 className="text-white">2. Best Answer Assessment:</h1>
                                <p className="text-[#FFFFFFB2] text-[12px] sm:text-[15px]">
                                    Uses Claude 3 to determine which model provided the best answer.
                                </p>
                            </div>
                            <div className="flex flex-col gap-[8px]">
                                <h1 className="text-white">3. Evaluation Review:</h1>
                                <p className="text-[#FFFFFFB2] text-[12px] sm:text-[15px]">
                                    Reviews and adjusts evaluations, ensuring accuracy and validating the analysis.
                                </p>
                            </div>
                        </>
                    )}
                </div>
                {/* Inputs section */}
                <div className="flex flex-col gap-[15px] mt-6 w-full">
                    {/* <h1 className="text-white text-[15px] sm:text-[18px] lg:text-[22px]">Inputs</h1> */}
                    <p className="text-slate-50 text-base">
                        Upload your resume and the job description. I’ll help you tweak your CV to wow those recruiters! ✨
                    </p>

                    {templateId === "cvboosterbuddy" && (
                        <textarea
                            className="w-full h-[120px] p-2 rounded-md text-[#FFFFFFB2] bg-[#5F5BCD1A] border border-[#6763CF]"
                            placeholder="Enter the job description here..."
                            value={jobDescription}
                            onChange={(e) => setJobDescription(e.target.value)}
                        />
                    )}

                    <div
                        className="bg-[#5F5BCD1A] rounded-3xl cursor-pointer h-[193px] flex items-center justify-center border border-[#6763CF] border-dashed"
                        onClick={handleInputClick}
                    >
                        {!uploadedFile ? (
                            <>
                                <SVGUpload />
                                <input
                                    ref={fileRef}
                                    type="file"
                                    className="hidden"
                                    accept=".pdf"
                                    onChange={handleFileUpload}
                                />
                            </>
                        ) : (
                            <div className="flex items-center gap-4">
                                <FaFilePdf size={40} className="text-[#867ad7]" />
                                <p className="text-white">{uploadedFile.name}</p>
                                <FaTrashAlt
                                    size={20}
                                    className="text-[#867ad7] cursor-pointer"
                                    onClick={handleRemoveFile}
                                />
                            </div>
                        )}
                    </div>
                    <p className="text-[#9A9A9A] text-sm">Only support .pdf files</p>
                    {error && <p className="text-red-500">{error}</p>}
                </div>

                {/* Run button */}
                <div
                    onClick={handleRunButton}
                    className={`button-1 cursor-pointer bg-transparent ring-1 z-10 ring-[#615fb0] relative py-4 px-20 text-slate-300 rounded-30 flex justify-center items-center shadow-[0_0_50px_rgba(97,95,176,0.5)] hover:shadow-[0_0_50px_10px_rgba(97,95,176,0.5)] max-sm:py-[10px] max-sm:px-[15px] max-sm:text-xs ${loading ? "opacity-50 pointer-events-none" : ""}`}
                >
                    {loading ? "Processing..." : "Run"}
                    <div
                        className="bg-transparent z-30 rounded-30 absolute top-[60%] left-[50%] transform -translate-x-1/2 -translate-y-1/2"
                        style={{
                            background: "radial-gradient(circle, rgba(119,116,213,0.3) 0%, rgba(16,18,23,0) 100%)",
                            boxShadow: "0px 0px 100px 10px rgba(119,116,213,0.4)",
                            backdropFilter: "blur(20px)",
                        }}
                    ></div>
                </div>
                {/* Processing your request... */}
                {loading && <p className="text-white">Making greatness... hang tight!</p>}
                {error && <p className="text-red-500">{error}</p>}
            </div>
        </div>
    );
}

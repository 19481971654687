// src/authConfig.js

export const msalConfig = {
    auth: {
      clientId: "2d6469ad-da42-4da9-b3b8-c88b894bf5ed", // Application (client) ID from Azure AD B2C
      authority: "https://faangB2C.b2clogin.com/faangB2C.onmicrosoft.com/B2C_1_signup_signin_b2cdev", // B2C_1_signupsignin is the name of the user flow (policy)
      redirectUri: "http://localhost:3000/auth/callback",  // Replace with your app's redirect URI
      knownAuthorities: ["faangB2C.b2clogin.com"],  // Your B2C tenant
    },
    cache: {
      cacheLocation: "localStorage",  // or sessionStorage
      storeAuthStateInCookie: false,
    },
  };
  
  export const loginRequest = {
    scopes: ["openid", "profile", "email"],
  };
  
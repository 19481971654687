import React from "react";
import SVGFB from "../../assets/SVG/SVGFB";
import SVGX from "../../assets/SVG/SVGX";
import SVGInsta from "../../assets/SVG/SVGInsta";
import faanglogo from "../../assets/faang_white_logo.png";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full h-auto mt-12 bg-slate-950 flex flex-col shadow-custom-shadow-4">
      <div className="p-8 max-w-[1340px] mx-auto max-sm:p-2">
        <div className="flex flex-col">
          <div className="flex justify-between w-full space-x-12 mx-auto max-sm:flex-col max-sm:space-x-0">
            <div className="w-full flex flex-col justify-between space-y-4 my-4">
              {/* <div>
                <h1 className="text-[#5F5BCD] text-4xl font-bold max-sm:text-2xl">
                  Faang
                </h1>
                <span className="text-slate-50 text-xl max-sm:text-base">
                  Pictch Yourself
                </span>
              </div> */}
              <div
                className="w-fit text-lg font-semibold tracking-widest text-gray-900 uppercase rounded-lg dark-mode:text-white focus:outline-none focus:shadow-outline cursor-pointer"
                onClick={() => navigate("/")}
              >
                <div className="flex flex-col">
                  <img
                    style={{ objectFit: "contain", height: "34px", marginTop: "2px" }}
                    src={faanglogo}
                    alt="Faang Logo"
                  />
                </div>
              </div>
              <div className="text-[#9E9EAB] max-sm:text-xs">
                Create personalized interview experiences that adapt to your unique style and needs It empowers you to design faster than ever.
              </div>
              <div className="flex items-center space-x-6">
                <span className="text-slate-400">
                  <SVGFB />
                </span>
                <span className="text-slate-400">
                  <SVGX />
              </span>
                <span className="text-slate-400">
                  <SVGInsta />
                </span>
              </div>
            </div>
            <div className="w-full flex justify-end space-x-12 my-4 max-sm:space-x-8 max-sm:justify-start max-sm:text-xs">
              <div className="flex flex-col text-[#9E9EAB] space-y-2 font-medium">
                <Link to="/templates" className="cursor-pointer hover:text-slate-100">
                  Buddy Templates
                </Link>
                <Link to="/blog-post" className="cursor-pointer hover:text-slate-100">
                  Blog Post
                </Link>
                <Link to="/faang-team" className="cursor-pointer hover:text-slate-100">
                  FAANG Team
                </Link>
              </div>
              <div className="flex flex-col text-[#9E9EAB] space-y-2 font-medium">
                <Link to="/aboutus" className="cursor-pointer hover:text-slate-100">
                  About Us
                </Link>
                <Link to="/privacy-policy" className="cursor-pointer hover:text-slate-100">
                  Privacy Policy
                </Link>
              </div>
            </div>
          </div>
          <hr className="w-full my-4 text-slate-800 mx-auto" />
        </div>

        <div className="w-full my-4 mx-auto flex justify-between items-center max-sm:text-[10px]">
          <span className="text-[#9E9EAB]">
            &#169; Copyright, Faang. All Right Reserved
          </span>
          <span className="text-[#9E9EAB]">Terms & Conditions</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React from "react";
import sample from '../../../../assets/reviewpicture.jpg';

const ReviewCard = ({ rating, text, name, position, imgSrc }) => {
    return (
        <div style={{
            // background: '#0e0d25',
            border: '1px solid #333333',
        }}
            className="text-white flex flex-col items-center gap-4 p-4 md:p-6 lg:p-6 rounded-xl border border-transparent bg-[rgba(32,35,43,0.50)] max-w-xs md:max-w-sm lg:max-w-md mx-auto">
            <div
                className="bg-transparent z-30 rounded-full absolute top-[60%] left-[50%] transform -translate-x-1/2 -translate-y-1/2"
                style={{
                    background:
                        "radial-gradient(circle, rgba(119,116,213,0.3) 0%, rgba(16,18,23,0) 100%)",
                    boxShadow: "0px 0px 80px 80px rgba(119,116,213,0.5)",
                    backdropFilter: "blur(15px)",
                }}
            ></div>
            <div className="flex gap-3 md:gap-4 mb-3 md:mb-4">
                {[...Array(5)].map((_, i) => (
                    <span key={i} className="text-yellow-500 text-sm md:text-base">&#9733;</span>
                ))}
            </div>

            <p className="text-sm md:text-base mb-3 md:mb-4 text-center">{text}</p>
            <img
                src={sample}
                alt={name}
                className="z-100 w-10 h-10 md:w-12 md:h-12 rounded-full object-cover mb-3 md:mb-4"
            />

            <p className="font-bold text-base md:text-lg">{name}</p>
            <p className="text-xs md:text-sm text-gray-400">{position}</p>
        </div>
    );
};

export default ReviewCard;

// apiService.js
import axios from 'axios';

const API_URL = 'https://dashboard.faangai.com/api/process-template';  // Point to your Flask API

// const API_URL = '/api/process-template';  // Point to your Flask API

export const TemplateFunction = async (file, templatetype, jobDescription = '') => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('templatetype', templatetype);
    if (templatetype === 'cvboosterbuddy') {
        formData.append('job_description', jobDescription);
    }

    try {
        const response = await axios.post(API_URL, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error uploading PDF:", error);
        throw error;
    }
};

import React from "react";
import dashboard from '../../../assets/dashboard.png';
import { useInView } from 'react-intersection-observer';

const DashboardDemo = () => {
  const { ref, inView } = useInView({
    threshold: 0.1, // Trigger when 10% of the component is visible
    triggerOnce: true, // Animation triggers only once
  });

  return (
    <section className="w-full mt-12" ref={ref}>
      <div
        className={`border-[1.5px] slide-container mx-auto mb-8 w-[90%] max-w-[1100px] rounded-lg md:rounded-[20px] backdrop-blur-lg bg-gradient-to-b from-[#0c081f] to-[#0c081f] border border-transparent shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-[0_8px_30px_rgba(255,255,255,0.15),_0_0_50px_rgba(255,255,255,0.3)] ${
          inView ? 'active' : ''
        }`}
        style={{
          boxShadow:
            '0 4px 20px rgba(0, 0, 0, 0.5), 0 0 25px rgba(255, 255, 255, 0.2)',
          background:
            'linear-gradient(#0c081f, #0c081f) padding-box, linear-gradient(180deg, rgba(255, 255, 255, 0.13) -0.61%, rgba(255, 255, 255, 0.46) -0.43%, rgba(255, 255, 255, 0.2) 13.63%, rgba(255, 255, 255, 0.03) 100%) border-box',
        }}
      >
        <img
          src={dashboard}
          alt="Description"
          className="w-full h-auto rounded-t-lg md:rounded-t-[20px] filter brightness-110"
        />
      </div>
    </section>
  );
};

export default DashboardDemo;

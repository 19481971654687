import { useState, useEffect } from "react";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa"; // Import social icons

const CVBoosterBuddyFeedback = ({ feedbackData }) => {
    const { strengths, areas_for_improvement, playful_insights } = feedbackData.feedback;

    const handleSocialShare = (platform) => {
        console.log(`Sharing on ${platform}`);
    };

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page
    }, []);

    return (
        <div className="max-w-[1340px] h-auto mx-auto flex flex-col max-sm:p-[10px]">
            <div className="lg:w-[1038px] my-[50px] mx-auto flex flex-col gap-[50px] items-center">
                <div className="lg:w-[786px] flex flex-col gap-[40px] items-center">
                    <h1 className="text-[22px] font-bold text-center sm:text-[40px] bg-gradient-to-r from-[#E1E1E1] to-[#929292] bg-clip-text text-transparent">
                        CV Booster Buddy
                    </h1>
                    <p className="leading-relaxed text-center text-[15px] text-[#E9E9E9B2] mt-4">
                        Here's the feedback based on your resume and job description!
                    </p>
                </div>

                {/* Strengths */}
                <div className="flex flex-col gap-[20px] px-[30px] w-full">
                    <div className="flex justify-between items-center">
                        <h2 className="text-[22px] text-white">Strengths</h2>
                        <div className="flex justify-center items-center gap-4">
                            <FaFacebookF
                                className="text-[#E9E9E9] cursor-pointer hover:text-blue-500"
                                onClick={() => handleSocialShare("Facebook")}
                            />
                            <FaTwitter
                                className="text-[#E9E9E9] cursor-pointer hover:text-blue-400"
                                onClick={() => handleSocialShare("Twitter")}
                            />
                            <FaLinkedinIn
                                className="text-[#E9E9E9] cursor-pointer hover:text-blue-600"
                                onClick={() => handleSocialShare("LinkedIn")}
                            />
                            <button
                                onClick={() => handleSocialShare("Share")}
                                className="text-sm text-[#E9E9E9] bg-transparent border border-[#615fb0] rounded-full px-3 py-1 hover:bg-[#615fb0] hover:text-white"
                            >
                                Share
                            </button>
                        </div>
                    </div>

                    <hr className="border-[#E9E9E9] opacity-50 mt-2 mb-4" /> {/* Separator line */}
                    {strengths.map((strength, index) => (
                        <div
                            key={index}
                            className="flex flex-col gap-[20px] p-[20px] md:p-[32px] bg-[#7A77CC1A] rounded-[12px] w-full relative"
                        >
                            <p className="text-[15px] text-[#E9E9E9B2] leading-relaxed">{strength}</p>
                        </div>
                    ))}
                </div>

                {/* Areas for Improvement */}
                <div className="flex flex-col gap-[20px] px-[30px] w-full mt-10">
                <div className="flex justify-between items-center">
                        <h2 className="text-[22px] text-white">Areas for Improvement</h2>
                        <div className="flex justify-center items-center gap-4">
                            <FaFacebookF
                                className="text-[#E9E9E9] cursor-pointer hover:text-blue-500"
                                onClick={() => handleSocialShare("Facebook")}
                            />
                            <FaTwitter
                                className="text-[#E9E9E9] cursor-pointer hover:text-blue-400"
                                onClick={() => handleSocialShare("Twitter")}
                            />
                            <FaLinkedinIn
                                className="text-[#E9E9E9] cursor-pointer hover:text-blue-600"
                                onClick={() => handleSocialShare("LinkedIn")}
                            />
                            <button
                                onClick={() => handleSocialShare("Share")}
                                className="text-sm text-[#E9E9E9] bg-transparent border border-[#615fb0] rounded-full px-3 py-1 hover:bg-[#615fb0] hover:text-white"
                            >
                                Share
                            </button>
                        </div>
                    </div>
                    <hr className="border-[#E9E9E9] opacity-50 mt-2 mb-4" /> {/* Separator line */}
                    {areas_for_improvement.map((area, index) => (
                        <div
                            key={index}
                            className="flex flex-col gap-[20px] p-[20px] md:p-[32px] bg-[#7A77CC1A] rounded-[12px] w-full relative"
                        >
                            <p className="text-[15px] text-[#E9E9E9B2] leading-relaxed">{area}</p>
                        </div>
                    ))}
                </div>

                {/* Playful Insights */}
                <div className="flex flex-col gap-[20px] px-[30px] w-full mt-10">
                    <div className="flex justify-between items-center">
                        <h2 className="text-[22px] text-white">Playful Insights</h2>
                        <div className="flex justify-center items-center gap-4">
                            <FaFacebookF
                                className="text-[#E9E9E9] cursor-pointer hover:text-blue-500"
                                onClick={() => handleSocialShare("Facebook")}
                            />
                            <FaTwitter
                                className="text-[#E9E9E9] cursor-pointer hover:text-blue-400"
                                onClick={() => handleSocialShare("Twitter")}
                            />
                            <FaLinkedinIn
                                className="text-[#E9E9E9] cursor-pointer hover:text-blue-600"
                                onClick={() => handleSocialShare("LinkedIn")}
                            />
                            <button
                                onClick={() => handleSocialShare("Share")}
                                className="text-sm text-[#E9E9E9] bg-transparent border border-[#615fb0] rounded-full px-3 py-1 hover:bg-[#615fb0] hover:text-white"
                            >
                                Share
                            </button>
                        </div>
                    </div>
                    <hr className="border-[#E9E9E9] opacity-50 mt-2 mb-4" /> {/* Separator line */}
                    <div className="flex flex-col gap-[20px] p-[20px] md:p-[32px] bg-[#7A77CC1A] rounded-[12px] w-full relative">
                        <p className="text-[15px] text-[#E9E9E9B2] leading-relaxed">{playful_insights}</p>
                    </div>
                </div>

          
            </div>
        </div>
    );
};

export default CVBoosterBuddyFeedback;

import React from 'react'

const SVGTick = () => {
  return (
    <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5 1.78442C5.89999 1.78442 2.16666 5.51776 2.16666 10.1178C2.16666 14.7178 5.89999 18.4511 10.5 18.4511C15.1 18.4511 18.8333 14.7178 18.8333 10.1178C18.8333 5.51776 15.1 1.78442 10.5 1.78442ZM13.8583 8.61777L9.96664 12.5011C9.84998 12.6261 9.69165 12.6844 9.52498 12.6844C9.36665 12.6844 9.20831 12.6261 9.08331 12.5011L7.14166 10.5594C6.89999 10.3178 6.89999 9.91773 7.14166 9.67607C7.38332 9.4344 7.78332 9.4344 8.02499 9.67607L9.52498 11.1761L12.975 7.73444C13.2167 7.48444 13.6167 7.48444 13.8583 7.73444C14.1 7.9761 14.1 8.36777 13.8583 8.61777Z"
              fill="white"
            />
          </svg>
  )
}

export default SVGTick
import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const blogPosts = [
  {
    id: 1,
    title: "Mastering the Art of Interviewing with AI",
    summary:
      "Discover how FAANG's AI-powered platform can help you build confidence and refine your interview skills.",
    image: "https://images.unsplash.com/photo-1504384308090-c894fdcc538d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMzA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&q=80&w=800",
    date: "October 24, 2024",
  },
  {
    id: 2,
    title: "Top 5 Tips for Your Next Job Interview",
    summary:
      "Boost your chances of success with these key tips for nailing any job interview.",
    image: "https://images.unsplash.com/photo-1504384308090-c894fdcc538d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMzA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&q=80&w=800",
    date: "October 10, 2024",
  },
  {
    id: 3,
    title: "Why Soft Skills Matter in Tech Interviews",
    summary:
      "Learn why communication and adaptability are just as important as coding skills.",
    image: "https://images.unsplash.com/photo-1504384308090-c894fdcc538d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMzA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&q=80&w=800",
    date: "September 25, 2024",
  },
];

const BlogPage = () => {
  const navigate = useNavigate();

  const handleReadMore = (id) => {
    navigate(`/blog/${id}`);
  };

  return (
    <motion.div
      className="!pt-12 max-w-[1340px] mx-auto py-16 px-6 lg:px-8 flex flex-col items-center"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeOut" }}
    >
      <div className="w-full max-w-[1000px] mx-auto">
        <h1 className="text-[26px] font-bold text-center mb-4 sm:text-[36px] bg-gradient-to-r from-[#E1E1E1] to-[#929292] bg-clip-text text-transparent">
          Welcome to Our Blog
        </h1>
        <p className="text-center text-[#b1b1b1] dark:text-[#d1d1d1] text-[16px] mb-12">
          Stay updated with the latest insights, tips, and industry trends.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogPosts.map((post) => (
            <div
              key={post.id}
              className="p-6 rounded-lg shadow-md bg-[#1e1e2a] bg-opacity-60 backdrop-blur-lg backdrop-saturate-150 flex flex-col"
            >
              <img
                src={post.image}
                alt={post.title}
                className="w-full h-[180px] object-cover rounded-lg mb-4"
              />
              <h2 className="text-xl font-semibold text-white mb-2">
                {post.title}
              </h2>
              <p className="text-[#b1b1b1] dark:text-[#d1d1d1] text-sm mb-4">
                {post.date}
              </p>
              <p className="text-[#cfcfcf] mb-4">{post.summary}</p>
              <button
                onClick={() => handleReadMore(post.id)}
                className="mt-auto bg-indigo-500 text-white font-semibold py-2 px-4 rounded-full shadow-md hover:bg-indigo-600 transition-all duration-300"
              >
                Read More
              </button>
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default BlogPage;
